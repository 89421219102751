import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { isMobile } from 'react-device-detect';
import { Icon } from '@iconify/react';
import { Link, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useNavigationIdz } from '../../context/useNavigation';

// Components
import Menu from "../../components/Home/Menu";
import Header from "../../components/Header";
import Footer from "../../components/Home/Footer";
import Banner from "../../components/Institucional/Banner";
import Contexto from "../../components/Institucional/Contexto";


// Styles
import './Institucional.scss';
import 'react-tooltip/dist/react-tooltip.css'
import api from '../../services/apiIdz';


function Institucional() {

    const { navigation, idzOrigin, idzEvent,loading } = useNavigationIdz()
    const [institucional, setInstitucional] = useState({})
    const { url } = useParams()
    useEffect(() => {
        if (!loading) {
            idzOrigin(window.location.href)
            idzEvent("paginas_acessadas", "Institucional")
        }

    }, [loading])

    const getInstitucional = async (query) => {
        let searchForId = true;
        try {
            const response = await api.get(`/api/conteudo-institucional${searchForId ? `/${query}` : `?seo_url=${query}`}`);
            console.log("Dados recebidos da API:", response.data);
    
            if (Array.isArray(response.data) && response.data.length > 0) {
                setInstitucional(response.data[0]); // Pegando o primeiro item corretamente
                console.log("setInstitucional", response.data[0]); 
            } else {
                setInstitucional({}); // Evita valores indefinidos
            }
            
        } catch (error) {
            console.error('Erro ao buscar os institucionais:', error);
        }
    };
    

    useEffect(() => {
        if (url) {
            getInstitucional(url)
        }
    }, [])

    return (
        <>
            <Menu />
            <Header />
            <Banner subtitulo="" titulo={institucional['titulo']} frase="" background={institucional['banner']} />
            <Contexto texto={institucional?.conteudo || "<p>Carregando conteúdo...</p>"} />
            <Footer />
        </>

    );
}

export default Institucional;
