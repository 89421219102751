import React from 'react';
import { Col, Container, Row } from "react-bootstrap";

// Style
import './Contexto.scss';
import 'react-tooltip/dist/react-tooltip.css';

function Contexto({ texto, titulo }) {

    console.log("texto", texto)

    const decodeHtml = (html) => {
        if (!html) return "";
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    };

    return (
        <>
            <section className='Contexto'>
                <Container>
                    <Row className='justify-content-md-center'>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='textContexto'>
                                {titulo && <h2>{titulo}</h2>}
                                <div dangerouslySetInnerHTML={{ __html: decodeHtml(texto) }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Contexto;
